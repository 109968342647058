import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

const bannerSliders = document.querySelectorAll('.js-banner-slider');

if (bannerSliders && bannerSliders.length > 0) {
    bannerSliders.forEach((slider, key) => {
        const sliderClass = `js-banner-slider-${key}`;
        slider.classList.add(`${sliderClass}`);

        const swiper = new Swiper(`.${sliderClass}`, {
            modules: [Navigation, Pagination, EffectFade, Autoplay],
            slidesPerView: 1,
            crossFade: true,
            effect: 'fade',
            autoplay: {
                delay: 2000,
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            pagination: {
                el: `.${sliderClass} .swiper-pagination`,
                clickable: true,
                renderBullet: function (index, className) {
                    let header = slider.querySelectorAll(
                        '.swiper-slide .banner-slider__title'
                    )[index].innerText;
                    let formattedIndex = (index + 1)
                        .toString()
                        .padStart(2, '0');

                    return `<span class="banner-slider__nav-bullet ${className}">${formattedIndex} / ${header}</span>`;
                },
            },
        });
    });
}
